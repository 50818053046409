<template>
  <div
    v-if="richText"
    :class="$style.root"
  >
    <CoreTextComponent
      :string="richText"
    />

    <CoreTextComponent
      v-if="richTextTruncated && isTruncated"
      :string="richTextTruncated"
      :class="$style.textTruncated"
    />

    <button
      v-if="richTextTruncated && !isTruncated"
      :class="$style.button"
      @click="isTruncated = true"
      tabindex="0"
    >
      {{ $t('shared.readMore') }}
    </button>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps({
  richText: {
    type: String,
    required: true
  },
  richTextTruncated: {
    type: String,
    required: false
  }
})

const isTruncated = ref(false)
</script>

<style module>
.root {
}

.textTruncated {
  margin-top: var(--lh, 1em);
}

.button {
  composes: reset-button button-focus font-size-small from global;

  padding: var(--padding--element);

  backdrop-filter: var(--backdrop-filter--dark);

  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--font-small--line-height);
}

.button:hover {
  backdrop-filter: var(--backdrop-filter--light);
}

.button:focus-within {
  outline: var(--outline--focus);
}
</style>
